<template>
  <div class="fourCards">
    <div class="container-flued">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <h4>Our Services</h4>
            <h5>Driving Value With Emerging Technology And Tools</h5>
            <h6>
              Our team is dedicated to providing cutting-edge solutions that
              elevate your brand and drive success.
            </h6>
          </div>
          <div class="col-lg-3">
            <div class="zCard">
              <svg
                fill="rgba(163, 76, 183, 1)"
                width="80px"
                height="80px"
                viewBox="-1 0 19 19"
                xmlns="http://www.w3.org/2000/svg"
                class="cf-icon-svg"
              >
                <path
                  d="M16.5 9.5a8 8 0 1 1-8-8 8 8 0 0 1 8 8zm-2.97.006a5.03 5.03 0 1 0-5.03 5.03 5.03 5.03 0 0 0 5.03-5.03zm-7.383-.4H4.289a4.237 4.237 0 0 1 2.565-3.498q.1-.042.2-.079a7.702 7.702 0 0 0-.907 3.577zm0 .8a7.7 7.7 0 0 0 .908 3.577q-.102-.037-.201-.079a4.225 4.225 0 0 1-2.565-3.498zm.8-.8a9.04 9.04 0 0 1 .163-1.402 6.164 6.164 0 0 1 .445-1.415c.289-.615.66-1.013.945-1.013.285 0 .656.398.945 1.013a6.18 6.18 0 0 1 .445 1.415 9.078 9.078 0 0 1 .163 1.402zm3.106.8a9.073 9.073 0 0 1-.163 1.402 6.187 6.187 0 0 1-.445 1.415c-.289.616-.66 1.013-.945 1.013-.285 0-.656-.397-.945-1.013a6.172 6.172 0 0 1-.445-1.415 9.036 9.036 0 0 1-.163-1.402zm1.438-3.391a4.211 4.211 0 0 1 1.22 2.591h-1.858a7.698 7.698 0 0 0-.908-3.577q.102.037.201.08a4.208 4.208 0 0 1 1.345.906zm-.638 3.391h1.858a4.238 4.238 0 0 1-2.565 3.498q-.1.043-.2.08a7.697 7.697 0 0 0 .907-3.578z"
                />
              </svg>
              <h5>Web & Software<br />Development</h5>
              <p>Full-Stack Development Services</p>
              <a href="#" @click.prevent="goto('WebSoft')">More Info</a>
            </div>
          </div>
          <div class="col-lg-3">
            <div class="zCard">
              <svg
                width="80px"
                height="80px"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12 18H12.01M11 6H13M9.2 21H14.8C15.9201 21 16.4802 21 16.908 20.782C17.2843 20.5903 17.5903 20.2843 17.782 19.908C18 19.4802 18 18.9201 18 17.8V6.2C18 5.0799 18 4.51984 17.782 4.09202C17.5903 3.71569 17.2843 3.40973 16.908 3.21799C16.4802 3 15.9201 3 14.8 3H9.2C8.0799 3 7.51984 3 7.09202 3.21799C6.71569 3.40973 6.40973 3.71569 6.21799 4.09202C6 4.51984 6 5.07989 6 6.2V17.8C6 18.9201 6 19.4802 6.21799 19.908C6.40973 20.2843 6.71569 20.5903 7.09202 20.782C7.51984 21 8.07989 21 9.2 21Z"
                  stroke="rgba(163, 76, 183, 1)"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              <h5>Mobile App<br />Development</h5>
              <p>Web app and native app Development Services</p>
              <a href="#" @click.prevent="goto('MobileApp')">More Info</a>
            </div>
          </div>
          <div class="col-lg-3">
            <div class="zCard">
              <svg
                fill="rgba(163, 76, 183, 1)"
                height="80px"
                width="80px"
                version="1.1"
                id="Layer_1"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                viewBox="0 0 512 512"
                xml:space="preserve"
              >
                <g>
                  <g>
                    <path
                      d="M329.584,446.924h-54.048v-46.965H236.46v46.965h-54.047c-10.79,0-19.538,8.748-19.538,19.538s8.748,19.538,19.538,19.538
			h147.17c10.79,0,19.538-8.748,19.538-19.538S340.374,446.924,329.584,446.924z"
                    />
                  </g>
                </g>
                <g>
                  <g>
                    <path
                      d="M292.47,335.162h-72.941H0v23.339c0,8.495,6.911,15.407,15.406,15.407h481.188c8.495,0,15.406-6.912,15.406-15.407
			v-23.339H292.47z"
                    />
                  </g>
                </g>
                <g>
                  <g>
                    <path
                      d="M496.593,26.001H15.406C6.911,26.001,0,32.912,0,41.407v267.705h36.029C38.332,189.77,136.117,93.393,255.999,93.393
			c41.389,0,80.141,11.495,113.245,31.449l12.922-17.653c4.249-5.803,12.399-7.065,18.204-2.816c5.805,4.25,7.066,12.4,2.816,18.205
			l-12.456,17.015c50.802,39.466,83.911,100.668,85.239,169.519h36.029V41.407C511.999,32.912,505.088,26.001,496.593,26.001z"
                    />
                  </g>
                </g>
                <g>
                  <g>
                    <path
                      d="M366.263,295.11c-4.593-27.761-19.035-51.599-39.406-68.26l-38.799,53.002c6.403,5.458,11.407,12.507,14.38,20.539h65.959
			C367.335,298.859,366.587,297.072,366.263,295.11z"
                    />
                  </g>
                </g>
                <g>
                  <g>
                    <path
                      d="M255.999,201.658c-32.011,0-61.097,13.363-81.586,35.216c-0.095,0.142-0.177,0.288-0.279,0.427
			c-0.675,0.927-1.452,1.731-2.3,2.424c-13.291,15.196-22.584,34.139-26.099,55.384c-0.324,1.962-1.072,3.749-2.133,5.282h65.959
			c6.985-18.876,25.165-32.369,46.437-32.369c2.709,0,5.365,0.228,7.957,0.649l40.899-55.872
			C290.05,205.643,273.461,201.658,255.999,201.658z"
                    />
                  </g>
                </g>
                <g>
                  <g>
                    <path
                      d="M449.543,300.392c-3.773-56.674-32-106.762-74.193-139.786l-33.2,45.354c1.144,0.918,2.29,1.833,3.406,2.789
			c24.662,21.129,41.143,50.289,46.407,82.109c0.574,3.474-0.281,6.853-2.137,9.534H449.543z"
                    />
                  </g>
                </g>
                <g>
                  <g>
                    <path
                      d="M120.036,290.857c3.527-21.322,12.113-41.437,24.801-58.754l-45.292-32.935c-0.048-0.035-0.09-0.076-0.137-0.111
			c-21.09,28.8-34.44,63.607-36.951,101.335h59.716C120.317,297.709,119.462,294.331,120.036,290.857z"
                    />
                  </g>
                </g>
                <g>
                  <g>
                    <path
                      d="M116.211,179.075l46.008,33.456c1.382-1.286,2.787-2.552,4.225-3.784c21.626-18.526,48.411-29.822,76.531-32.504v-56.356
			C193.313,123.196,148.71,145.272,116.211,179.075z"
                    />
                  </g>
                </g>
                <g>
                  <g>
                    <path
                      d="M269.025,119.889v56.356c17.997,1.714,35.447,6.95,51.345,15.364l33.421-45.656
			C328.59,131.182,299.777,121.938,269.025,119.889z"
                    />
                  </g>
                </g>
              </svg>
              <h5>Quality <br />Assurance</h5>
              <p>In house testing and Quality management Services</p>
              <a href="#" @click.prevent="goto('QualityAssurance')"
                >More Info</a
              >
            </div>
          </div>
          <div class="col-lg-3">
            <div class="zCard">
              <svg
                fill="rgba(163, 76, 183, 1)"
                version="1.1"
                id="Capa_1"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                width="80px"
                height="80px"
                viewBox="0 0 64.325 64.324"
                xml:space="preserve"
              >
                <g>
                  <path
                    d="M5.865,34.72c-1.906,0-3.754-0.614-4.957-1.231L0,37.184c1.114,0.616,3.344,1.202,5.602,1.202
		c5.426,0,7.977-2.815,7.977-6.129c0-2.786-1.643-4.604-5.132-5.895c-2.552-0.969-3.666-1.525-3.666-2.786
		c0-1.027,0.938-1.907,2.874-1.907s3.344,0.558,4.135,0.939l0.998-3.607c-1.174-0.528-2.816-0.997-5.045-0.997
		c-4.663,0-7.479,2.58-7.479,5.953c0,2.874,2.141,4.692,5.426,5.836c2.375,0.851,3.314,1.555,3.314,2.785
		C9.004,33.869,7.918,34.72,5.865,34.72z"
                  />
                  <polygon
                    points="29.211,34.427 21.088,34.427 21.088,29.735 28.36,29.735 28.36,26.098 21.088,26.098 21.088,21.992 28.801,21.992 
		28.801,18.326 16.6,18.326 16.6,38.093 29.211,38.093 	"
                  />
                  <path
                    d="M44.326,39.593c2.775,0,5.318-0.999,7.298-2.653l3.937,3.674c-0.006,0.41,0.143,0.816,0.465,1.117l5.777,5.394
		c0.289,0.27,0.655,0.403,1.022,0.403c0.401,0,0.802-0.16,1.097-0.478c0.565-0.604,0.532-1.556-0.072-2.12l-5.777-5.393
		c-0.322-0.301-0.74-0.42-1.146-0.387l-3.885-3.625c1.671-1.983,2.684-4.541,2.684-7.332c0-6.285-5.112-11.397-11.397-11.397
		S32.929,21.91,32.929,28.194C32.928,34.48,38.041,39.593,44.326,39.593z"
                  />
                </g>
              </svg>
              <h5>Search Engine Optimization</h5>
              <p>We optimize your web for search engines</p>
              <a href="#" @click.prevent="goto('SeO')">More Info</a>
            </div>
          </div>
          <div class="col-lg-3">
            <div class="zCard">
              <svg
                width="80px"
                height="80px"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill="none"
                  stroke="rgba(163, 76, 183, 1)"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M16.5,19 C17.8807119,19 19,17.8807119 19,16.5 C19,15.1192881 17.8807119,14 16.5,14 C15.1192881,14 14,15.1192881 14,16.5 C14,17.8807119 15.1192881,19 16.5,19 Z M10,5 L12,3 M7.5,10 C8.88071187,10 10,8.88071187 10,7.5 C10,6.11928813 8.88071187,5 7.5,5 C6.11928813,5 5,6.11928813 5,7.5 C5,8.88071187 6.11928813,10 7.5,10 Z M8,16 L16,8 M5.5,21 C6.88071187,21 8,19.8807119 8,18.5 C8,17.1192881 6.88071187,16 5.5,16 C4.11928813,16 3,17.1192881 3,18.5 C3,19.8807119 4.11928813,21 5.5,21 Z M18.5,8 C19.8807119,8 21,6.88071187 21,5.5 C21,4.11928813 19.8807119,3 18.5,3 C17.1192881,3 16,4.11928813 16,5.5 C16,6.88071187 17.1192881,8 18.5,8 Z M12,21 L14,19"
                />
              </svg>
              <h5>Technology Consultancy</h5>
              <p>We guide you to select most effective solutions</p>
              <a href="#" @click.prevent="goto('TC')">More Info</a>
            </div>
          </div>
          <div class="col-lg-3">
            <div class="zCard">
              <svg
                fill="rgba(163, 76, 183, 1)"
                width="80px"
                height="80px"
                viewBox="0 0 14 14"
                role="img"
                focusable="false"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M 5.2,6.4 7.6,8.8 5.2,11.2 1,7 5.2,2.8 6.25,3.85 5.65,4.45 5.2,4 2.2,7 5.2,10 6.4070312,8.79297 4.6,7 5.2,6.4 Z M 8.8,2.8 6.4,5.2 8.8,7.6 9.4,7 7.5929688,5.20703 8.8,4 l 3,3 -3,3 L 8.35,9.55 7.75,10.15 8.8,11.2 13,7 8.8,2.8 Z"
                />
              </svg>
              <h5>Branding<br /><br /></h5>
              <p>We market your products as a brand</p>
              <a href="#" @click.prevent="goto('BS')">More Info</a>
            </div>
          </div>
          <div class="col-lg-3">
            <div class="zCard">
              <svg
                width="80px"
                height="80px"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M11.5144 1.12584C11.8164 0.958052 12.1836 0.958052 12.4856 1.12584L21.4845 6.12522C21.4921 6.12942 21.4996 6.13372 21.5071 6.13813C21.8125 6.31781 22 6.64568 22 7V17C22 17.3632 21.8031 17.6978 21.4856 17.8742L12.4856 22.8742C12.1791 23.0445 11.8059 23.0416 11.5022 22.8673L2.51436 17.874C2.19689 17.6977 2 17.3631 2 16.9999V7C2 6.64568 2.18749 6.3177 2.49287 6.13802L2.5073 6.13784L2.51436 6.12584L11.5144 1.12584ZM12.0001 10.856L5.05923 6.99995L12 3.14396L18.9409 7L12.0001 10.856ZM4 8.69951V16.4115L11 20.3004V12.5884L4 8.69951ZM13 12.5884V20.3005L20 16.4116V8.69951L13 12.5884Z"
                  fill="rgba(163, 76, 183, 1)"
                />
              </svg>
              <h5>3D Animation <br />Services</h5>
              <p>3D Animation Services Unity,Blender</p>
              <a href="#">More Info</a>
            </div>
          </div>
          <div class="col-lg-3">
            <div class="zCard">
              <svg
                width="80px"
                height="80px"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M16 10L18.5768 8.45392C19.3699 7.97803 19.7665 7.74009 20.0928 7.77051C20.3773 7.79703 20.6369 7.944 20.806 8.17433C21 8.43848 21 8.90095 21 9.8259V14.1741C21 15.099 21 15.5615 20.806 15.8257C20.6369 16.056 20.3773 16.203 20.0928 16.2295C19.7665 16.2599 19.3699 16.022 18.5768 15.5461L16 14M6.2 18H12.8C13.9201 18 14.4802 18 14.908 17.782C15.2843 17.5903 15.5903 17.2843 15.782 16.908C16 16.4802 16 15.9201 16 14.8V9.2C16 8.0799 16 7.51984 15.782 7.09202C15.5903 6.71569 15.2843 6.40973 14.908 6.21799C14.4802 6 13.9201 6 12.8 6H6.2C5.0799 6 4.51984 6 4.09202 6.21799C3.71569 6.40973 3.40973 6.71569 3.21799 7.09202C3 7.51984 3 8.07989 3 9.2V14.8C3 15.9201 3 16.4802 3.21799 16.908C3.40973 17.2843 3.71569 17.5903 4.09202 17.782C4.51984 18 5.07989 18 6.2 18Z"
                  stroke="rgba(163, 76, 183, 1)"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              <h5>Video Editing<br />Expertise</h5>
              <p>Video Editing Expertise services</p>
              <a href="#">More Info</a>
            </div>
          </div>
        </div>
        <!--/row-->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FourCards',
  props: {
    msg: String,
  },
  methods: {
    goto(selectedName) {
      this.$router.push({ name: selectedName, replace: true });
      setTimeout(function () {
        location.reload();
      }, 100);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.fourCards {
  //background-image: url('@/assets/FourCardsBG.jpg');
  background-color: #ffffff15;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding-bottom: 40px;
  h4 {
    color: rgb(197, 141, 209);
    font-family: Inter;
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-align: center;
    margin-top: 40px;
    text-shadow: 0 2px 1px #00000005, -1px 3px 1px #00000010,
      -2px 5px 1px #00000015;
  }
  h5 {
    color: #ffffff;
    font-family: Inter;
    font-size: 32px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    text-align: center;
    margin-bottom: 0px;
  }
  h6 {
    color: #ffffff;
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    text-align: center;
    margin-bottom: 30px;
  }
  .zCard {
    margin: 20px;
    border-radius: 8px;
    background: rgba(163, 76, 183, 0.05);
    // background: linear-gradient(
    //   0deg,
    //   rgba(163, 76, 183, 1) 0%,
    //   rgba(163, 76, 183, 0.7) 71%
    // );
    border: 1px solid rgb(197, 141, 209);
    text-align: center;
    padding-top: 40px;
    padding-bottom: 50px;
    padding-left: 20px;
    padding-right: 20px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    box-sizing: border-box;
    backdrop-filter: blur(20px);
    img {
      display: inline-block;
    }
    h5 {
      color: rgb(197, 141, 209);
      font-family: Inter;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      margin-top: 20px;
      margin-bottom: 0px;
    }
    p {
      color: #fff;
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 300;
      line-height: normal;
      margin-top: 10px;
      margin-bottom: 30px;
      text-align: center;
    }
    a {
      border-radius: 8px;
      border: 2px solid rgba(163, 76, 183, 1);
      background-color: rgba(163, 76, 183, 1);
      color: #fff;
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      padding: 4px 12px;
      text-decoration: none;
    }
    a:hover {
      color: rgba(163, 76, 183, 1);
      background-color: #ffffff;
      transition-duration: 1s;
    }
  }
}
</style>
