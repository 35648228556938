<template>
  <div class="fourCards">
    <div class="container-flued">
      <div class="container">
        <div class="row">
          <div class="col-12"></div>
          <!-- <div class="col-lg-6">
            <div class="row">
              <p>
                Clowdsol, a dynamic company based in Islamabad, is seeking a
                skilled and experienced Upwork professional to join our team. In
                this exciting role, you'll leverage your Upwork expertise to
                source and secure new projects that align with our business
                goals. This is a on-site opportunity with office hours from 6PM
                to 12AM PST.
              </p>
              <h5>Responsibilities:</h5>
              <ul>
                <li>
                  Develop and implement a comprehensive Upwork sourcing strategy
                  to identify and target potential clients.
                </li>
                <li>
                  Craft compelling proposals that showcase Clowdsol's expertise
                  and win over clients.
                </li>
                <li>
                  Negotiate project scope, budgets, and timelines to ensure
                  win-win agreements.
                </li>
                <li>
                  Manage ongoing communication with clients throughout the
                  project lifecycle.
                </li>
                <li>
                  Maintain detailed records of Upwork activity and project
                  progress.
                </li>
                <li>
                  Collaborate with internal teams to ensure successful project
                  delivery.
                </li>
              </ul>
              <h5>You'll be a perfect fit if you:</h5>
              <ul>
                <li>
                  Possess proven experience and a stellar track record of
                  securing high-quality projects.
                </li>
                <li>
                  Write compelling proposals that showcase Clowdsol's expertise
                  and seal the deal.
                </li>
                <li>
                  Secure projects with favorable terms for scope, budget, and
                  timelines.
                </li>
                <li>
                  Maintain seamless communication with clients throughout the
                  project journey.
                </li>
                <li>
                  Keep meticulous records of Upwork activity and project
                  progress.
                </li>
                <li>
                  Collaborate effectively with internal teams to deliver stellar
                  results.
                </li>
              </ul>
              <h5>Bonus points if you:</h5>
              <ul>
                <li>
                  Have experience in web development, graphic design, or video
                  editing (relevant to Clowdsol's services).
                </li>
                <li>
                  Possess SEO and marketing knowledge to attract Upwork clients.
                </li>
              </ul>
              <p class="secondPara">
                NOTE: This is your chance to transition from freelance to
                permanent based on your performance. Secure at least 3 projects
                for Clowdsol through Upwork within the first month, and a
                permanent position awaits!
              </p>
            </div>
          </div> -->
          <div class="col-lg-12">
            <div class="zCard">
              <h4>About Us</h4>
              <p>
                At ClowdSol, we transcend the conventional role of a tech
                solutions provider. We are the architects of innovation,
                creators of digital excellence, and your committed partners in
                success. Our specialization in top-notch and cost-effective
                technology development and graphic design solutions positions us
                as the premier global tech solutions partner.
              </p>
              <div class="row">
                <div class="col-lg-4 morder">
                  <img src="@/assets/story.png" class="w200" />
                </div>
                <div class="col-lg-8 morder2 flexAlignCenter">
                  <h5>Our Journey</h5>
                  <!-- <p>
                    <b>Web Development:</b> Elevate your online presence with
                    our expert web development services. From sleek,
                    user-friendly interfaces to robust backend solutions, we
                    engineer websites that stand out in the digital landscape.
                  </p>
                  <p>
                    <b>Graphic Design:</b> Immerse your brand in captivating
                    visuals with our graphic design expertise. Our creative team
                    transforms ideas into visually stunning concepts, ensuring
                    your brand leaves a lasting impression.
                  </p> -->
                  <p>
                    <b>Our Capabilities:</b> ClowdSol stands as a global
                    powerhouse at the intersection of technology transformation
                    and creative design. As a multi-disciplinary firm, we take
                    pride in offering avant-garde solutions that span a spectrum
                    of services, including graphics, client-centric solutions,
                    product and packaging design, website development, digital
                    experience design, advertising, communications, and
                    videography.
                  </p>
                  <p>
                    <b>Vision:</b> Revolutionizing Technology. At ClowdSol, our
                    vision is to lead a technology revolution, where creativity
                    and innovation converge to shape the future of businesses
                    and industries worldwide. We strive to pioneer new
                    solutions, challenge existing norms, and empower our clients
                    with cutting-edge technologies that drive success in the
                    digital age.
                  </p>
                  <p>
                    <b>Mission:</b> Elevating Success through Innovative Design
                    and Technology.<br />
                    Our mission at ClowdSol is twofold: to foster the growth of
                    our company and to solve the problems of people through
                    innovative design and technology. We are committed to
                    leveraging our expertise and creativity to craft products
                    and experiences that not only represent our clients' brands
                    but also propel them forward in the ever-evolving digital
                    landscape.
                  </p>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-8 mOrder flexAlignCenter">
                  <OurCapabilites />
                </div>
                <div class="col-lg-4 mOrder2">
                  <img src="@/assets/process.png" class="w200" />
                </div>
              </div>
              <div class="row">
                <div class="col-lg-4">
                  <img src="@/assets/WhyUs.png" class="w100" />
                </div>
                <div class="col-lg-8">
                  <h5>Why ClowdSol?</h5>
                  <p>
                    <b>Strategic Partnerships:</b> Establishing enduring
                    alliances with companies, software firms, and individuals
                    globally, our mission revolves around infusing creativity
                    and seasoned expertise to engineer superior information
                    solutions. We don't just provide services; we build lasting
                    partnerships for mutual success.
                  </p>
                  <p>
                    <b>Innovation in Our DNA:</b> Driven by innovation, ClowdSol
                    pioneers a new era of products and services. Our
                    unparalleled strengths lie in extensive experience, profound
                    industry knowledge, and adeptness in crafting technological
                    solutions poised to meet the challenges of tomorrow. When
                    you choose ClowdSol, you choose a partner committed to
                    leading the way in innovation.
                  </p>
                  <p>
                    <b>Redefined Excellence:</b> Poised to redefine excellence
                    in web development and graphic design, we leverage our
                    understanding of industry workflows and processes to create
                    forward-looking solutions. ClowdSol's commitment to
                    excellence ensures your digital presence not only meets but
                    exceeds the evolving needs of the digital landscape.
                  </p>
                  <p>
                    <b>Diverse Clientele:</b> We proudly cater to a diverse
                    clientele, including SMBs, minority-based businesses, and
                    women-owned enterprises. Our solutions are tailored to meet
                    the unique requirements of each client, ensuring a
                    personalized and impactful digital presence. Your success is
                    our success.
                  </p>
                </div>
              </div>

              <!-- <a href="#">More Info</a> -->
            </div>
          </div>
        </div>
        <!--/row-->
      </div>
    </div>
  </div>
</template>

<script>
import OurCapabilites from '@/components/AboutUs/OurCapabilities.vue';
export default {
  name: 'AboutPage',
  components: {
    OurCapabilites,
  },
  props: {
    msg: String,
  },
  data() {
    return {
      name: '',
      email: '',
      message: '',
      nameErrorMsg: '',
      emailErrorMsg: '',
      messageErrorMsg: '',
      apiResponse: '',
      position: '',
      phone: '',
      file: '',
    };
  },
  methods: {},
};
// var words = [
//     'We help your company go through its digtial transformations process making it into an agile and exponential coampany.',
//     'Clowdsol aims to reduce your workload, save your time, cut your unnecessary cost of hiring and maintaining an IT function, and boost your profitability. We hope you want the same. So why the wait? Let’s connect and start a new journey!',
//   ],
//   part,
//   i = 0,
//   offset = 0,
//   len = words.length,
//   forwards = true,
//   skip_count = 0,
//   skip_delay = 15,
//   speed = 70;
// var wordflick = function () {
//   setInterval(function () {
//     if (forwards) {
//       if (offset >= words[i].length) {
//         ++skip_count;
//         if (skip_count == skip_delay) {
//           forwards = false;
//           skip_count = 0;
//         }
//       }
//     } else {
//       if (offset <= 0) {
//         forwards = true;
//         i++;
//         offset = 0;
//         if (i >= len) {
//           i = 0;
//         }
//       }
//     }
//     part = words[i].substr(0, offset);
//     if (skip_count == 0) {
//       if (forwards) {
//         offset++;
//       } else {
//         offset = offset - 10;
//       }
//     }
//     if (document.getElementById('word')) {
//       document.getElementById('word').innerHTML = part;
//     }
//   }, speed);
//};

document.addEventListener('DOMContentLoaded', function () {
  //wordflick();
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.w100 {
  width: 90%;
  margin: 0 auto;
  margin-top: 0px;
  display: block !important;
}
.w200 {
  width: 100%;
  opacity: 1;
  margin-top: 50px;
}
.flexAlignCenter {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.flip-card {
  background-color: transparent;
  width: 100%;
  height: 300px;
  border: 1px solid rgb(197, 141, 209);
  border-radius: 20px;
  perspective: 1000px; /* Remove this if you don't want the 3D effect */
  margin-top: 20px;
}

/* This container is needed to position the front and back side */
.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.8s;
  transform-style: preserve-3d;
  border-radius: 20px;
}

/* Do an horizontal flip when you move the mouse over the flip box container */
.flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
}

/* Position the front and back side */
.flip-card-front,
.flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden; /* Safari */
  backface-visibility: hidden;
}

/* Style the front side (fallback if image is missing) */
.flip-card-front {
  color: black;
  border-radius: 20px;
  background: rgba(163, 76, 183, 0.2);
  backdrop-filter: blur(20px);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  h1 {
    color: rgb(197, 141, 209);
  }
  span {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 20px;
  }
}

/* Style the back side */
.flip-card-back {
  background-color: rgba(163, 76, 183, 0.7);
  color: #ffffffaa;
  transform: rotateY(180deg);
  border-radius: 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  p {
    font-size: 20px;
  }
  padding: 20px;
}
.form-select:focus {
  border-color: #00000000;
  outline: 0;
  box-shadow: none;
  color: #ffffff;
  border-bottom: 1px solid rgb(197, 141, 209);
}
select {
  color: #ffffff;
  background-color: #000000;
  border: 0px;
  border-bottom: 1px solid rgb(197, 141, 209);
}
.form-group {
  margin-top: 20px;
  label {
    color: #ffffff;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 200;
    line-height: normal;
    float: left;
  }
  input {
    background: #00000055;
    border: 0px;
    border-bottom: 1px solid rgb(197, 141, 209);
    color: #ffffff;
  }
  textarea {
    background: #00000055;
    border: 0px;
    border-bottom: 1px solid rgb(197, 141, 209);
    color: #ffffff;
  }
  .alert {
    color: red;
    font-weight: 300;
    padding: 5px 10px;
    border-radius: 0px;
  }
}
.form-control:focus {
  border-color: rgba(226, 184, 66, 0.91);
  outline: 0;
  box-shadow: none;
}
.btn-primary {
  background-color: rgba(163, 76, 183, 1);
  border-color: rgba(163, 76, 183, 1);
  width: 100%;
  color: #ffffff;
  margin-bottom: 0px;
}
.btn-primary:hover {
  background-color: rgb(197, 141, 209);
  border-color: rgb(197, 141, 209);
}
.btn-primary:active,
.btn-primary:focus {
  background-color: rgb(197, 141, 209);
  border-color: rgb(197, 141, 209);
}
.btn:first-child:active {
  background-color: rgb(197, 141, 209);
  border-color: rgb(197, 141, 209);
}
h3 {
  color: #ffffff;
  font-family: Inter;
  font-size: 48px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-align: center;
  margin-top: 40px;
}
h4 {
  color: #292341;
  font-family: Inter;
  font-size: 36px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
h5 {
  color: #fff;
  font-family: Inter;
  font-size: 21px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  display: inline-block;
  text-align: left;
}
p {
  color: #fff;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 200;
  line-height: normal;
  display: inline-block;
  margin-top: 20px;
  text-align: justify;
}
ul li {
  color: #fff;
  font-family: Inter;
  font-size: 17px;
  font-style: normal;
  font-weight: 200;
  line-height: 140%;
  text-align: justify;
  margin-left: 30px;
}
.secondPara {
  margin-top: 0px;
}
#word {
  color: #ffffff;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  text-align: left;
  margin-bottom: 30px;
  min-height: 60px;
  max-width: 800px;
  margin: 0 auto;
}
.fourCards {
  //background-image: url('@/assets/FourCardsBG.jpg');
  background-color: #ffffff15;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding-bottom: 0px;
  padding-top: 20px;
  h4 {
    color: rgb(197, 141, 209);
    font-family: Inter;
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-align: center;
    margin-top: 0px;
    text-shadow: 0 2px 1px #00000005, -1px 3px 1px #00000010,
      -2px 5px 1px #00000015;
  }
  h5 {
    color: #ffffff;
    font-family: Inter;
    font-size: 21px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: left;
    margin-bottom: 0px;
  }
  h6 {
    color: #ffffff;
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    text-align: center;
    margin-bottom: 30px;
  }
  .zCard {
    margin: 20px;
    border-radius: 8px;
    // background: linear-gradient(
    //   0deg,
    //   rgba(163, 76, 183, 1) 0%,
    //   rgba(163, 76, 183, 0.7) 71%
    // );
    border: 1px solid rgba(163, 76, 183, 1);
    padding-top: 40px;
    padding-bottom: 20px;
    padding-left: 20px;
    padding-right: 20px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    box-sizing: border-box;
    background: rgba(163, 76, 183, 0.05);
    backdrop-filter: blur(20px);
    img {
      display: inline-block;
    }
    h5 {
      color: #ffffff;
      font-family: Inter;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      margin-top: 20px;
      margin-bottom: 0px;
      text-align: left;
      width: 100%;
    }
    p {
      color: #ffffff;
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 300;
      line-height: normal;
      margin-top: 10px;
      min-height: 50px;
      b {
        display: inline-block;
        font-weight: 800;
      }
    }
    a {
      border-radius: 8px;
      border: 2px solid rgba(163, 76, 183, 1);
      background-color: rgba(163, 76, 183, 1);
      color: #fff;
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      padding: 4px 12px;
      text-decoration: none;
    }
  }
}
@media screen and (max-width: 1300px) {
  .fourCards {
    .zCard {
      padding-left: 5px;
      padding-right: 5px;
      h5 {
        min-height: 48px;
      }
    }
  }
}
@media screen and (max-width: 992px) {
  div.mOrder2 {
    order: 1;
  }
  div.mOrder {
    order: 2;
  }
}
</style>
