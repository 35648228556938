<template>
  <div class="FaQ">
    <div class="container-flued">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <h4>Our Process</h4>
            <h5>
              Elevating Brands through Creative and Digital Transformation
            </h5>
            <p>
              Embark on a transformative journey with Clowdsol. Whether you're
              looking for creative design solutions or digital transformation
              services, we're here to guide you at every step. Let's shape your
              brand's future together.
            </p>
          </div>
          <div class="col-12">
            <div class="panel-group" id="accordion">
              <div class="panel panel-default">
                <div class="panel-heading">
                  <h5 class="panel-title">
                    <a
                      data-bs-toggle="collapse"
                      data-bs-parent="#accordion"
                      href="#collapse1"
                    >
                      Discovery
                      <span class="active">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="22"
                          height="22"
                          viewBox="0 0 22 22"
                          fill="none"
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M5.76451 6L5 6.63259L9.54354 10.5059L9.059 10.919L9.06175 10.9166L5.02476 14.358L5.7777 15C6.89365 14.0491 10.0088 11.3938 11.05 10.5059C10.2767 9.84623 11.0308 10.4892 5.76451 6Z"
                            fill="rgba(53, 164, 251, 1)"
                          />
                        </svg>
                      </span>
                    </a>
                  </h5>
                </div>
                <div id="collapse1" class="panel-collapse collapse in">
                  <div class="panel-body collapse-text">
                    At Clowdsol, our process begins with a thorough discovery
                    phase. We delve into the core aspects of your business,
                    understanding your industry, values, passion, personality,
                    target audience, and goals. This crucial step forms the
                    foundation for the transformation journey we embark on
                    together.
                  </div>
                </div>
              </div>
              <div class="panel panel-default">
                <div class="panel-heading">
                  <h5 class="panel-title">
                    <a
                      data-bs-toggle="collapse"
                      data-bs-parent="#accordion"
                      href="#collapse2"
                    >
                      Crafting
                      <span class="active">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="22"
                          height="22"
                          viewBox="0 0 22 22"
                          fill="none"
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M5.76451 6L5 6.63259L9.54354 10.5059L9.059 10.919L9.06175 10.9166L5.02476 14.358L5.7777 15C6.89365 14.0491 10.0088 11.3938 11.05 10.5059C10.2767 9.84623 11.0308 10.4892 5.76451 6Z"
                            fill="rgba(53, 164, 251, 1)"
                          />
                        </svg>
                      </span>
                    </a>
                  </h5>
                </div>
                <div id="collapse2" class="panel-collapse collapse in">
                  <div class="panel-body collapse-text">
                    With insights gathered during the discovery phase, we
                    meticulously craft a winning strategy. This strategy is
                    designed to bring forth your unique voice and set you apart
                    from the crowd. Whether it's creative design or digital
                    solutions, our approach is tailored to enhance your brand's
                    identity and impact.
                  </div>
                </div>
              </div>
              <div class="panel panel-default">
                <div class="panel-heading">
                  <h5 class="panel-title">
                    <a
                      data-bs-toggle="collapse"
                      data-bs-parent="#accordion"
                      href="#collapse3"
                    >
                      Deploying
                      <span class="active">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="22"
                          height="22"
                          viewBox="0 0 22 22"
                          fill="none"
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M5.76451 6L5 6.63259L9.54354 10.5059L9.059 10.919L9.06175 10.9166L5.02476 14.358L5.7777 15C6.89365 14.0491 10.0088 11.3938 11.05 10.5059C10.2767 9.84623 11.0308 10.4892 5.76451 6Z"
                            fill="rgba(53, 164, 251, 1)"
                          />
                        </svg>
                      </span>
                    </a>
                  </h5>
                </div>
                <div id="collapse3" class="panel-collapse collapse in">
                  <div class="panel-body collapse-text">
                    The next phase involves deploying the brand-building
                    strategy we've crafted for you. This is where we tell your
                    unique story and showcase your expertise. Through innovative
                    design, technological solutions, and effective
                    communication, we ensure that your brand message resonates
                    with your audience and creates a lasting impression.
                  </div>
                </div>
              </div>
              <div class="panel panel-default">
                <div class="panel-heading">
                  <h5 class="panel-title">
                    <a
                      data-bs-toggle="collapse"
                      data-bs-parent="#accordion"
                      href="#collapse4"
                    >
                      Growth
                      <span class="active">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="22"
                          height="22"
                          viewBox="0 0 22 22"
                          fill="none"
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M5.76451 6L5 6.63259L9.54354 10.5059L9.059 10.919L9.06175 10.9166L5.02476 14.358L5.7777 15C6.89365 14.0491 10.0088 11.3938 11.05 10.5059C10.2767 9.84623 11.0308 10.4892 5.76451 6Z"
                            fill="rgba(53, 164, 251, 1)"
                          />
                        </svg>
                      </span>
                    </a>
                  </h5>
                </div>
                <div id="collapse4" class="panel-collapse collapse in">
                  <div class="panel-body collapse-text">
                    We don't stop at deployment; our commitment extends to
                    relentless growth. We continuously audit results, refine
                    content, and work towards expanding your audience. Our goal
                    is to align your brand with success, ensuring that the
                    creative or digital transformation journey we initiate
                    translates into tangible business success for you.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'OurCapabilities',
  props: {
    msg: String,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.fourCards {
  .zCard {
    text-align: left !important;
  }
}
@keyframes gradient {
  0% {
    background-position: 0% 0%;
  }
  50% {
    background-position: 100% 100%;
  }
  100% {
    background-position: 0% 0%;
  }
}

.wave {
  background: rgb(255 255 255 / 25%);
  border-radius: 1000% 1000% 0 0;
  position: fixed;
  width: 200%;
  height: 12em;
  animation: wave 10s -3s linear infinite;
  transform: translate3d(0, 0, 0);
  opacity: 0.8;
  bottom: 0;
  left: 0;
  z-index: -1;
}

.wave:nth-of-type(2) {
  bottom: -1.25em;
  animation: wave 18s linear reverse infinite;
  opacity: 0.8;
}

.wave:nth-of-type(3) {
  bottom: -2.5em;
  animation: wave 20s -1s reverse infinite;
  opacity: 0.9;
}

@keyframes wave {
  2% {
    transform: translateX(1);
  }

  25% {
    transform: translateX(-25%);
  }

  50% {
    transform: translateX(-50%);
  }

  75% {
    transform: translateX(-25%);
  }

  100% {
    transform: translateX(1);
  }
}
.FaQ {
  // background-image: url('@/assets/faqBg.jpg');
  // background-color: #cccccc;
  // background-position: center;
  // background-repeat: no-repeat;
  // background-size: cover;
  padding-bottom: 60px;
  margin: auto;
  font-family: -apple-system, BlinkMacSystemFont, sans-serif;
  overflow: auto;
  animation: gradient 15s ease infinite;
  background-size: 400% 400%;
  background-attachment: fixed;
  padding-bottom: 40px;
  h4 {
    color: #ffffff;
    font-family: Open Sans;
    font-size: 24px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    margin-top: 30px;
    text-align: left;
  }
  h5 {
    color: #ffffff;
    font-family: Open Sans;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-align: left;
  }
  p {
    color: #ffffff;
    font-family: Open Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: left;
  }
  .panel-heading {
    // border-bottom: 2px solid #ffc93c;
    width: 100%;
    position: relative;
    .active {
      right: 10px;
      background-color: #000000;
      border-radius: 50%;
      width: 20px;
      height: 20px;
      padding-left: 2px;
      svg {
        display: block;
        padding-top: 0px;
      }
    }
    .panel-title a {
      color: #ffffff;
      font-family: Open Sans;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: 16px; /* 66.667% */
      width: 100%;
      text-decoration: none;
    }
  }
  .panel-body {
    color: #ffffff;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: 139%; /* 27.8px */
    padding-top: 15px;
    padding-bottom: 30px;
  }
  .panel-default {
    border-bottom: 2px solid rgba(163, 76, 183, 1);
    margin-bottom: 15px;
    margin-top: 10px;
  }
}
.panel-heading .collapsed .active {
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  transform: rotate(0deg);
  position: absolute;
  float: right;
  transition-duration: 1s;
}

.panel-heading .active {
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
  position: absolute;
  float: right;
  transition-duration: 1s;
}
@media screen and (max-width: 992px) {
  .FaQ {
    h4 {
      font-size: 24px;
    }
    .panel-heading {
      .panel-title a {
        font-size: 16px;
        font-weight: 400;
        line-height: 16px; /* 66.667% */
        padding-right: 40px;
      }
    }
    .panel-body {
      font-size: 14px;
    }
  }
}
</style>
