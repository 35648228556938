<template>
  <div class="FaQ">
    <div class="container-flued">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <h4>Frequently Asked Questions</h4>
          </div>
          <div class="col-1"></div>
          <div class="col-10">
            <div class="panel-group" id="accordion">
              <div class="panel panel-default">
                <div class="panel-heading">
                  <h5 class="panel-title">
                    <a
                      data-bs-toggle="collapse"
                      data-bs-parent="#accordion"
                      href="#collapse1"
                    >
                      How long does it take to develop software with you?
                      <span class="active">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="22"
                          height="22"
                          viewBox="0 0 22 22"
                          fill="none"
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M5.76451 6L5 6.63259L9.54354 10.5059L9.059 10.919L9.06175 10.9166L5.02476 14.358L5.7777 15C6.89365 14.0491 10.0088 11.3938 11.05 10.5059C10.2767 9.84623 11.0308 10.4892 5.76451 6Z"
                            fill="rgba(53, 164, 251, 1)"
                          />
                        </svg>
                      </span>
                    </a>
                  </h5>
                </div>
                <div id="collapse1" class="panel-collapse collapse in">
                  <div class="panel-body collapse-text">
                    We need to know your project needs first in order to tell
                    you the exact time that is required to develop your
                    software. To tell you, it is possible to run a project from
                    start to finish in a couple of weeks, but it’s also possible
                    to run a project for several years. For a more precise
                    answer, we suggest you contact a member of our business
                    development team. They will be able to provide you with the
                    most accurate estimate.
                  </div>
                </div>
              </div>
              <div class="panel panel-default">
                <div class="panel-heading">
                  <h5 class="panel-title">
                    <a
                      data-bs-toggle="collapse"
                      data-bs-parent="#accordion"
                      href="#collapse2"
                    >
                      What will it cost me to develop a product with you?
                      <span class="active">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="22"
                          height="22"
                          viewBox="0 0 22 22"
                          fill="none"
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M5.76451 6L5 6.63259L9.54354 10.5059L9.059 10.919L9.06175 10.9166L5.02476 14.358L5.7777 15C6.89365 14.0491 10.0088 11.3938 11.05 10.5059C10.2767 9.84623 11.0308 10.4892 5.76451 6Z"
                            fill="rgba(53, 164, 251, 1)"
                          />
                        </svg>
                      </span>
                    </a>
                  </h5>
                </div>
                <div id="collapse2" class="panel-collapse collapse in">
                  <div class="panel-body collapse-text">
                    To determine the project development cost, we must first
                    determine the scope of work. We then estimate the nature of
                    work and labor involved. We review the hourly rates of the
                    employees who are hired and then send you a spreadsheet.
                  </div>
                </div>
              </div>
              <div class="panel panel-default">
                <div class="panel-heading">
                  <h5 class="panel-title">
                    <a
                      data-bs-toggle="collapse"
                      data-bs-parent="#accordion"
                      href="#collapse3"
                    >
                      Do I need to be tech-savvy to work with you?
                      <span class="active">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="22"
                          height="22"
                          viewBox="0 0 22 22"
                          fill="none"
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M5.76451 6L5 6.63259L9.54354 10.5059L9.059 10.919L9.06175 10.9166L5.02476 14.358L5.7777 15C6.89365 14.0491 10.0088 11.3938 11.05 10.5059C10.2767 9.84623 11.0308 10.4892 5.76451 6Z"
                            fill="rgba(53, 164, 251, 1)"
                          />
                        </svg>
                      </span>
                    </a>
                  </h5>
                </div>
                <div id="collapse3" class="panel-collapse collapse in">
                  <div class="panel-body collapse-text">
                    It doesn’t matter how much technical knowledge you have. If
                    something is unclear or ambiguous, we will explain it to
                    you.
                  </div>
                </div>
              </div>
              <div class="panel panel-default">
                <div class="panel-heading">
                  <h5 class="panel-title">
                    <a
                      data-bs-toggle="collapse"
                      data-bs-parent="#accordion"
                      href="#collapse4"
                    >
                      How experienced are your developers?
                      <span class="active">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="22"
                          height="22"
                          viewBox="0 0 22 22"
                          fill="none"
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M5.76451 6L5 6.63259L9.54354 10.5059L9.059 10.919L9.06175 10.9166L5.02476 14.358L5.7777 15C6.89365 14.0491 10.0088 11.3938 11.05 10.5059C10.2767 9.84623 11.0308 10.4892 5.76451 6Z"
                            fill="rgba(53, 164, 251, 1)"
                          />
                        </svg>
                      </span>
                    </a>
                  </h5>
                </div>
                <div id="collapse4" class="panel-collapse collapse in">
                  <div class="panel-body collapse-text">
                    We have junior, mid-level, and senior engineers. You can
                    choose the engineer who aligns best with your project
                    requirements. Experience level won’t be an issue at our
                    software development company.
                  </div>
                </div>
              </div>
              <div class="panel panel-default">
                <div class="panel-heading">
                  <h5 class="panel-title">
                    <a
                      data-bs-toggle="collapse"
                      data-bs-parent="#accordion"
                      href="#collapse5"
                    >
                      Do you guarantee product quality?
                      <span class="active">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="22"
                          height="22"
                          viewBox="0 0 22 22"
                          fill="none"
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M5.76451 6L5 6.63259L9.54354 10.5059L9.059 10.919L9.06175 10.9166L5.02476 14.358L5.7777 15C6.89365 14.0491 10.0088 11.3938 11.05 10.5059C10.2767 9.84623 11.0308 10.4892 5.76451 6Z"
                            fill="rgba(53, 164, 251, 1)"
                          />
                        </svg>
                      </span>
                    </a>
                  </h5>
                </div>
                <div id="collapse5" class="panel-collapse collapse in">
                  <div class="panel-body collapse-text">
                    Well, we do not begin the development of the scope of the
                    project until it is clear enough. To test your product
                    early, we like to design wireframes or mockups. From the
                    very beginning, we ensure quality control by working with
                    the development team to verify that the standards are being
                    met. The key to success is communication, so we arrange
                    daily or weekly scrum meetings and stay accessible on
                    product development tools at all times.
                  </div>
                </div>
              </div>
              <div class="panel panel-default">
                <div class="panel-heading">
                  <h5 class="panel-title">
                    <a
                      data-bs-toggle="collapse"
                      data-bs-parent="#accordion"
                      href="#collapse6"
                    >
                      Do you practice agile methodology?
                      <span class="active">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="22"
                          height="22"
                          viewBox="0 0 22 22"
                          fill="none"
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M5.76451 6L5 6.63259L9.54354 10.5059L9.059 10.919L9.06175 10.9166L5.02476 14.358L5.7777 15C6.89365 14.0491 10.0088 11.3938 11.05 10.5059C10.2767 9.84623 11.0308 10.4892 5.76451 6Z"
                            fill="rgba(53, 164, 251, 1)"
                          />
                        </svg>
                      </span>
                    </a>
                  </h5>
                </div>
                <div id="collapse6" class="panel-collapse collapse in">
                  <div class="panel-body collapse-text">
                    Yes, we incorporate agile methodology in our software
                    development process in an attempt to make engineering more
                    flexible and efficient.
                  </div>
                </div>
              </div>
              <div class="panel panel-default">
                <div class="panel-heading">
                  <h5 class="panel-title">
                    <a
                      data-bs-toggle="collapse"
                      data-bs-parent="#accordion"
                      href="#collapse7"
                    >
                      Will there be any hidden or surprise charges involved?
                      <span class="active">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="22"
                          height="22"
                          viewBox="0 0 22 22"
                          fill="none"
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M5.76451 6L5 6.63259L9.54354 10.5059L9.059 10.919L9.06175 10.9166L5.02476 14.358L5.7777 15C6.89365 14.0491 10.0088 11.3938 11.05 10.5059C10.2767 9.84623 11.0308 10.4892 5.76451 6Z"
                            fill="rgba(53, 164, 251, 1)"
                          />
                        </svg>
                      </span>
                    </a>
                  </h5>
                </div>
                <div id="collapse7" class="panel-collapse collapse in">
                  <div class="panel-body collapse-text">
                    Worry not for there will be no hidden or surprise charges
                    involved because we don’t want you to end up paying
                    exorbitantly high. We are a software development company
                    that runs a transparent business model in which you will be
                    aware of the total development costs.
                  </div>
                </div>
              </div>
              <div class="panel panel-default">
                <div class="panel-heading">
                  <h5 class="panel-title">
                    <a
                      data-bs-toggle="collapse"
                      data-bs-parent="#accordion"
                      href="#collapse8"
                    >
                      What terms are included in a typical contract with
                      Clowdsol?
                      <span class="active">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="22"
                          height="22"
                          viewBox="0 0 22 22"
                          fill="none"
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M5.76451 6L5 6.63259L9.54354 10.5059L9.059 10.919L9.06175 10.9166L5.02476 14.358L5.7777 15C6.89365 14.0491 10.0088 11.3938 11.05 10.5059C10.2767 9.84623 11.0308 10.4892 5.76451 6Z"
                            fill="rgba(53, 164, 251, 1)"
                          />
                        </svg>
                      </span>
                    </a>
                  </h5>
                </div>
                <div id="collapse8" class="panel-collapse collapse in">
                  <div class="panel-body collapse-text">
                    A software contract is the written agreement between you and
                    Nextbridge that lays out each party’s obligations concerning
                    the software. The NDA includes the following core elements:
                    <ul>
                      <li>Usage &amp; Restriction</li>
                      <li>Ownership</li>
                      <li>Terms &amp; Termination</li>
                      <li>Fees &amp; Payment</li>
                      <li>Indemnification</li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="panel panel-default">
                <div class="panel-heading">
                  <h5 class="panel-title">
                    <a
                      data-bs-toggle="collapse"
                      data-bs-parent="#accordion"
                      href="#collapse9"
                    >
                      Are there post-development services offered by Clowdsol?
                      <span class="active">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="22"
                          height="22"
                          viewBox="0 0 22 22"
                          fill="none"
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M5.76451 6L5 6.63259L9.54354 10.5059L9.059 10.919L9.06175 10.9166L5.02476 14.358L5.7777 15C6.89365 14.0491 10.0088 11.3938 11.05 10.5059C10.2767 9.84623 11.0308 10.4892 5.76451 6Z"
                            fill="rgba(53, 164, 251, 1)"
                          />
                        </svg>
                      </span>
                    </a>
                  </h5>
                </div>
                <div id="collapse9" class="panel-collapse collapse in">
                  <div class="panel-body collapse-text">
                    Indeed we provide post-development services (support and
                    maintenance), and those include the following:
                    <ul>
                      <li>Consultation</li>
                      <li>30 days bug-backup</li>
                      <li>
                        Any software vulnerabilities, if reported within 30 days
                        after deployment
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-1"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FaQ',
  props: {
    msg: String,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@keyframes gradient {
  0% {
    background-position: 0% 0%;
  }
  50% {
    background-position: 100% 100%;
  }
  100% {
    background-position: 0% 0%;
  }
}

.wave {
  background: rgb(255 255 255 / 25%);
  border-radius: 1000% 1000% 0 0;
  position: fixed;
  width: 200%;
  height: 12em;
  animation: wave 10s -3s linear infinite;
  transform: translate3d(0, 0, 0);
  opacity: 0.8;
  bottom: 0;
  left: 0;
  z-index: -1;
}

.wave:nth-of-type(2) {
  bottom: -1.25em;
  animation: wave 18s linear reverse infinite;
  opacity: 0.8;
}

.wave:nth-of-type(3) {
  bottom: -2.5em;
  animation: wave 20s -1s reverse infinite;
  opacity: 0.9;
}

@keyframes wave {
  2% {
    transform: translateX(1);
  }

  25% {
    transform: translateX(-25%);
  }

  50% {
    transform: translateX(-50%);
  }

  75% {
    transform: translateX(-25%);
  }

  100% {
    transform: translateX(1);
  }
}
.FaQ {
  // background-image: url('@/assets/faqBg.jpg');
  // background-color: #cccccc;
  // background-position: center;
  // background-repeat: no-repeat;
  // background-size: cover;
  padding-bottom: 60px;
  margin: auto;
  font-family: -apple-system, BlinkMacSystemFont, sans-serif;
  overflow: auto;
  background: linear-gradient(
    315deg,
    rgba(53, 164, 251, 0.2) 3%,
    rgba(163, 76, 183, 0.2) 38%,
    rgba(53, 164, 251, 0.1) 68%,
    rgba(163, 76, 183, 0.1) 98%
  );
  animation: gradient 15s ease infinite;
  background-size: 400% 400%;
  background-attachment: fixed;
  padding-bottom: 40px;
  h4 {
    color: #ffffff;
    font-family: Open Sans;
    font-size: 36px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    margin-top: 60px;
    text-align: center;
    margin-bottom: 60px;
  }
  .panel-heading {
    // border-bottom: 2px solid #ffc93c;
    width: 100%;
    position: relative;
    .active {
      right: 10px;
      background-color: #000000;
      border-radius: 50%;
      width: 20px;
      height: 20px;
      padding-left: 2px;
      svg {
        display: block;
        padding-top: 0px;
      }
    }
    .panel-title a {
      color: #ffffff;
      font-family: Open Sans;
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: 16px; /* 66.667% */
      width: 100%;
      text-decoration: none;
    }
  }
  .panel-body {
    color: #ffffff;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 300;
    line-height: 139%; /* 27.8px */
    padding-top: 15px;
    padding-bottom: 30px;
  }
  .panel-default {
    border-bottom: 2px solid rgba(163, 76, 183, 1);
    margin-bottom: 40px;
    margin-top: 10px;
  }
}
.panel-heading .collapsed .active {
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  transform: rotate(0deg);
  position: absolute;
  float: right;
  transition-duration: 1s;
}

.panel-heading .active {
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
  position: absolute;
  float: right;
  transition-duration: 1s;
}
@media screen and (max-width: 992px) {
  .FaQ {
    h4 {
      font-size: 24px;
    }
    .panel-heading {
      .panel-title a {
        font-size: 18px;
        font-weight: 700;
        line-height: 16px; /* 66.667% */
      }
    }
    .panel-body {
      font-size: 14px;
    }
  }
}
</style>
